<template>
  <div class="container-top">
    <div class="container padding-top-60">
      <div style="text-align:center">
        <img src="/static/icon/u_check-circle.svg" class="svg-primary"
             style="width:54px;height:54px;margin-bottom: 32px">
        <div class="h4 main">서비스 개설 신청이 완료되었습니다.</div>
        <div class="body2-web sub2" style="margin:12px 0 32px 0">{{ desc }}</div>
        <div class="box-info">
          <div class="col-4 subtitle5 main" style="margin-bottom:24px">서비스 개설 정보</div>
          <div class="col-8 body2 sub" style="margin-bottom:24px">https://{{ order.key }}.launchpack.co.kr</div>
          <div class="col-4 subtitle5 main">진행상태</div>
          <div class="col-8 body2 primary">개설 중</div>
        </div>
      </div>
      <div class="box-title">개설자 정보</div>
      <div class="flex" style="flex-wrap: wrap">
        <div class="col-3 item-col subtitle5 main">신청자</div>
        <div class="col-9 item-col body2 sub">{{ user.name }}</div>
        <div class="col-3 item-col subtitle5 main">연락처</div>
        <div class="col-9 item-col body2 sub">{{ user.phone }}</div>
        <div class="col-3 item-col subtitle5 main">이메일</div>
        <div class="col-9 item-col body2 sub">{{ user.email }}</div>
      </div>
      <div class="box-title">결제 정보</div>
      <div class="flex" style="flex-wrap: wrap">
        <div class="col-3 item-col subtitle5 main">총 결제금액</div>
        <div class="col-9 item-col body2 sub"><span class="h5 main price">{{ order.price|currencyNum }}</span>원</div>
        <div class="col-3 item-col subtitle5 main">결제 수단</div>
        <div class="col-9 item-col body2 sub">카드결제 / {{ order.credit }}</div>
      </div>
      <div style="margin:80px 0 120px 0" class="flex-center">
        <button v-if="order.theme_type==='design'" class="button is-gray" style="width:242px" @click="clickDesign">디자인 수정 바로가기</button>
        <button v-else class="button is-gray" style="width:242px" @click="clickService">내 서비스 상세보기</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ServiceAccountDone",
    created() {

    },
    data() {
      return {}
    },
    methods: {
      clickDesign() {
        this.$router.replace('/mypage/my_design');
      },
      clickService() {
        this.$router.replace(`/mypage/my_service`);
        // this.$router.replace(`/mypage/my_service_detail?id=${this.order.id}`);
      }
    },
    computed: {
      order() {
        return this.$store.getters.accountDone;
      },
      desc() {
        return this.order.theme_type === 'design' ? '디자인 수정이 가능한 링크와 가이드가 1시간 이내 메일과 \n' +
          '마이페이지 디자인 수정 요청 페이지를 통해 전달될 예정입니다. 잠시만 기다려 주세요! :)'
          : '신청자 이메일로 서비스 개설 관련 안내문이 발송될 예정입니다.';
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .box-info
    background-color $gray4
    padding 40px
    border-radius 8px
    display flex
    flex-wrap wrap
    text-align left
    width 564px
    margin 32px auto

  .box-title
    padding 16px 0
    border-bottom 1px solid $sub5
    font-weight 700
    font-size 18px
    line-height 24px
    margin-top 70px

  .item-col
    padding-top 24px

  .body2-web
    white-space pre-line
</style>
